// src/components/CreateViceDealz.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreateViceDealz.module.css';

function CreateViceDealz({ onCreateNetwork = () => {} }) {
    const [formData, setFormData] = useState({
        name: '',
        apiKey: '',
        offerName: '',
        offerWebsite: '',
        domainName: '',
        apiName: '',
        endpointUrl: '',
        affSource: '',
        offerId: '', // Initially an empty string, converted to Number on submit
        template: 'ViceDealz'
    });

    const [message, setMessage] = useState({ text: '', type: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate and convert `offerId` to a number
        const offerIdNumber = parseInt(formData.offerId, 10);
        if (isNaN(offerIdNumber)) {
            setMessage({ text: 'Offer ID must be a valid number', type: 'error' });
            return;
        }

        setIsSubmitting(true);
        setMessage({ text: 'Creating ViceDealz network...', type: 'info' });

        const createEndpoint = `${process.env.REACT_APP_API_URL}/api/ViceDealz/networks/create`;

        try {
            const submissionData = {
                ...formData,
                offerId: offerIdNumber // Ensure `offerId` is sent as a number
            };

            // Step 1: Create the network
            const createResponse = await axios.post(createEndpoint, submissionData, {
                headers: { 'Content-Type': 'application/json' }
            });

            const newNetwork = createResponse.data;
            setMessage({ text: 'ViceDealz network created successfully!', type: 'success' });

            onCreateNetwork(newNetwork);

            // Step 2: Download the network files using the created network ID
            const downloadEndpoint = `${process.env.REACT_APP_API_URL}/api/ViceDealz/networks/${newNetwork._id}/download`;
            const downloadResponse = await axios.get(downloadEndpoint, { responseType: 'blob' });

            const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${newNetwork.apiName || 'vicedealz_network'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setFormData({
                name: '',
                apiKey: '',
                offerName: '',
                offerWebsite: '',
                domainName: '',
                apiName: '',
                affSource: '',
                endpointUrl: '',
                offerId: '',
                template: 'ViceDealz'
            });
            setMessage({ text: '', type: '' });

        } catch (error) {
            console.error('Error creating or downloading ViceDealz network:', error);
            setMessage({ text: 'Error creating or downloading ViceDealz network. Please try again.', type: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className={styles.card2}>
            {message.text && (
                <div className={`${styles.message} ${styles[message.type]}`}>
                    {message.text}
                </div>
            )}
            <h2>Please Enter Your API Credentials</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                {[
                    { label: "Network Name", name: "name", required: true },
                    { label: "API Key", name: "apiKey", required: true },
                    { label: "Offer Name", name: "offerName", required: true },
                    { label: "Offer Website", name: "offerWebsite", required: true },
                    { label: "Domain Name", name: "domainName", required: true },
                    { label: "API Name", name: "apiName", required: true },
                    { label: "AFF Source", name: "affSource", required: true },
                    { label: "Endpoint URL", name: "endpointUrl", required: true },
                    { label: "Offer ID", name: "offerId", required: true }  // Ensure offerId is filled
                ].map(({ label, name, required }) => (
                    <div className={styles.formRow} key={name}>
                        <label>{label}:</label>
                        <input
                            type="text"
                            name={name}
                            value={formData[name]}
                            onChange={handleChange}
                            required={required}
                        />
                    </div>
                ))}
                <div className={styles.formActions}>
                    <button type="submit" className={styles.createButton} disabled={isSubmitting}>
                        {isSubmitting ? 'Creating...' : 'Create'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CreateViceDealz;
