import React from 'react';
import Sidebar from '../dashboard/Sidebar';
import MainContent from './MainContent';
import styles from './Schedule.Schedule.module.css';

function Schedule() {
  return (
    <div className={styles.scheduleContainer}>
      <Sidebar />
      <div className={styles.scheduleMain}>
        <h2 className={styles.scheduleHeading}>Schedule Management</h2>
        <div className={styles.scheduleContent}>
          <MainContent />
        </div>
      </div>
    </div>
  );
}

export default Schedule;
