import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import Card1 from './Card1';
import Card2 from './Card2';
import axios from '../../utils/axiosConfig';
import styles from './ProxySettings.ps.module.css';

function ProxySettings() {
  const [proxyTemplates, setProxyTemplates] = useState([]);
  const [selectedProxyTemplate, setSelectedProxyTemplate] = useState('');
  const [geoDetails, setGeoDetails] = useState([]);
  const [proxyUsername, setProxyUsername] = useState('');
  const [proxyPassword, setProxyPassword] = useState('');
  const [startingPortGap, setStartingPortGap] = useState(0);
  const [result, setResult] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of geo details per page
  const [showCredentials, setShowCredentials] = useState(false);

  useEffect(() => {
    const fetchProxyTemplates = async () => {
      try {
        const response = await axios.get('/api/proxy');
        setProxyTemplates(response.data);
      } catch (error) {
        console.error('Error fetching proxy templates:', error);
      }
    };
    fetchProxyTemplates();
  }, []);

  const handleProxyTemplateChange = async (templateId) => {
    setSelectedProxyTemplate(templateId);

    if (templateId) {
      try {
        const response = await axios.get(`/api/proxy/${templateId}`);
        const data = response.data;
        setGeoDetails(data.geoDetails || []);
        setProxyUsername(data.username || '');
        setProxyPassword(data.password || '');
        setStartingPortGap(data.startingPortGap || 0);
      } catch (error) {
        console.error('Error fetching geo details:', error);
      }
    }
  };

  const handleSaveProxyTemplate = async () => {
    try {
      await axios.post('/api/proxy/save-settings', {
        templateId: selectedProxyTemplate,
        username: proxyUsername,
        password: proxyPassword,
        startingPortGap,
      });
      setResult('Proxy settings saved successfully!');
    } catch (error) {
      console.error('Error saving proxy settings:', error);
      setResult('Error saving proxy settings.');
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = geoDetails.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className={styles.proxySettingsContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
        <h2 className={styles.heading}>Proxy Settings</h2>
        <div className={styles.cardContainer}>
          {/* Card1 for Proxy Form */}
          <div className={styles.card1}>
            <Card1
              proxyTemplates={proxyTemplates}
              selectedProxyTemplate={selectedProxyTemplate}
              proxyUsername={proxyUsername}
              proxyPassword={proxyPassword}
              startingPortGap={startingPortGap}
              setProxyUsername={setProxyUsername}
              setProxyPassword={setProxyPassword}
              setStartingPortGap={setStartingPortGap}
              onProxyTemplateChange={handleProxyTemplateChange}
              onSave={handleSaveProxyTemplate}
            />
            {result && <p className={styles.resultMessage}>{result}</p>}
          </div>

          {/* Card2 for Geo Details */}
          <div className={styles.card2}>
            <Card2
              geoDetails={currentItems}
              showCredentials={showCredentials}
              toggleCredentials={() => setShowCredentials(!showCredentials)}
            />

            {/* Pagination */}
            <div className={styles.pagination}>
              {Array.from({ length: Math.ceil(geoDetails.length / itemsPerPage) }, (_, i) => (
                <button
                  key={i + 1}
                  className={`${styles.pageButton} ${currentPage === i + 1 ? styles.activePageButton : ''}`}
                  onClick={() => paginate(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProxySettings;
