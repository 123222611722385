import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// Register necessary components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement);

const BarChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chartInstance?.destroy(); // Destroy existing chart before creating a new one
    }
  }, [data]); // Re-render the chart when `data` changes

  const chartData = {
    labels: data.labels,  // Labels for the x-axis
    datasets: [
      {
        label: 'Metric Values',
        data: data.values,  // Data for the bars
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  return <Bar ref={chartRef} data={chartData} />;
};

export default BarChart;
