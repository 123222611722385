// src/components/create/CreateRoiBoomers.js

import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreateRoiBoomers.module.css';

const roiboomersFields = [
  'apiSecret', 'apiKey', 'apiPassword', 'domainName', 'apiName',
  'funnelLP', 'referralUrl', 'affSub', 'source'
];

function CreateRoiBoomers({ onCreateNetwork }) {
  const [formData, setFormData] = useState({
    networkName: '',
    template: 'RoiBoomers', // Fixed template set to 'RoiBoomers'
    apiSecret: '',
    apiKey: '',
    apiPassword: '',
    domainName: '',
    apiName: '',
    funnelLP: '',
    referralUrl: '',
    affSub: '',
    source: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Endpoint for creating RoiBoomers networks
    const createEndpoint = `${process.env.REACT_APP_API_URL}/api/roiboomers/networks/create`;

    try {
      // Create the network
      const createResponse = await axios.post(createEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const newRoiBoomers = createResponse.data;

      // Notify parent component of successful creation
      if (onCreateNetwork) onCreateNetwork(newRoiBoomers);

      // Trigger download using the network's ID
      const downloadEndpoint = `${process.env.REACT_APP_API_URL}/api/roiboomers/networks/${newRoiBoomers._id}/download`;
      const downloadResponse = await axios.get(downloadEndpoint, { responseType: 'blob' });

      const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${newRoiBoomers.apiName || 'roiboomers_template'}.zip`;
      link.click();

    } catch (error) {
      console.error('Error creating or downloading RoiBoomers configuration', error);
    }
  };

  return (
    <div className={styles.card2}>
      <h2>Please Enter Your API Credentials</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Network Name:</label>
            <input
              type="text"
              name="networkName"
              value={formData.networkName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Render form fields specific to RoiBoomers template */}
        {roiboomersFields.map(field => (
          <div className={styles.formGroup} key={field}>
            <label>{field}:</label>
            <input
              type="text"
              name={field}
              value={formData[field] || ''}
              onChange={handleChange}
            />
          </div>
        ))}

        <div className={styles.formActions}>
          <button type="submit" className={styles.createButton}>Create</button>
        </div>
      </form>
    </div>
  );
}

export default CreateRoiBoomers;
