import React, { useState, useEffect } from "react";
import Sidebar from "../dashboard/Sidebar";
import styles from "./CRM.crm.module.css";
import { fetchCRMData } from "../utils/Trackingapi"; // Import the CRM fetch function

const CRM = () => {
  const [dateRange, setDateRange] = useState("today");
  const [crmData, setCRMData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Fetch CRM data based on date range
  const getCRMData = async () => {
    try {
      const data = await fetchCRMData(dateRange);
      setCRMData(Array.isArray(data) ? data : []);
      setCurrentPage(1); // Reset pagination when new data is fetched
    } catch (error) {
      console.error("Error fetching CRM data:", error);
      setCRMData([]);
    }
  };

  // Trigger data fetch whenever the date range changes
  useEffect(() => {
    getCRMData();
  }, [dateRange]);

  // Pagination logic
  const totalPages = Math.ceil(crmData.length / itemsPerPage);
  const displayedCRMData = crmData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.crmContainer}>
      <Sidebar />
      <div className={styles.crmMain}>
        <h2 className={styles.crmHeading}>CRM Management</h2>

        {/* Date Filters */}
        <div className={styles.settingsCard}>
          <div className={styles.settingsCardSection}>
            <h3>Date Filters</h3>
            <div className="filter-buttons">
              {[
                { label: "Today", value: "today" },
                { label: "Yesterday", value: "yesterday" },
                { label: "This Week", value: "thisWeek" },
                { label: "Last Week", value: "lastWeek" },
                { label: "This Month", value: "thisMonth" },
                { label: "Last Month", value: "lastMonth" },
                { label: "Custom", value: "custom" },
              ].map((range) => (
                <button
                  key={range.value}
                  className={`button button-primary ${
                    dateRange === range.value ? "active" : ""
                  }`}
                  onClick={() => setDateRange(range.value)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* CRM Table */}
        <div className={styles.settingsCard}>
          <h3>CRM Data</h3>
          <table className={styles.crmTable}>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Offer Name</th>
                <th>API Name</th>
                <th>IP Address</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {displayedCRMData.length > 0 ? (
                displayedCRMData.map((crm, index) => {
                  // Normalize data fields
                  const ipAddress =
                    crm.ip || crm.IPAddress || crm.rawData?.ip || "N/A";

                  return (
                    <tr key={index}>
                      <td>{crm.firstName || "N/A"}</td>
                      <td>{crm.lastName || "N/A"}</td>
                      <td>{crm.email || "N/A"}</td>
                      <td>{crm.phone || "N/A"}</td>
                      <td>{crm.offerName || "N/A"}</td>
                      <td>{crm.apiName || "N/A"}</td>
                      <td>{ipAddress}</td>
                      <td>
                        {new Date(crm.timestamp).toLocaleDateString()}{" "}
                        {new Date(crm.timestamp).toLocaleTimeString()}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className={styles.noData}>
                    No CRM data available for the selected date range.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className={styles.pagination}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={currentPage === i + 1 ? styles.active : ""}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CRM;
