import React, { useEffect, useState } from 'react';
import styles from './Card2.ps.module.css';
import axios from '../../utils/axiosConfig'; // Use the custom axios instance

function Card2() {
  const [smartproxy, setSmartproxy] = useState(null);
  const [oxylabs, setOxylabs] = useState(null);
  const [geoDetails, setGeoDetails] = useState([]);
  const [error, setError] = useState('');
  const [showSmartproxyCredentials, setShowSmartproxyCredentials] = useState(false);
  const [showOxylabsCredentials, setShowOxylabsCredentials] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Items per page
  const [pingedIps, setPingedIps] = useState({});

  const fetchSettings = async () => {
    try {
      const settingsResponse = await axios.get('/api/proxy/get-settings');
      const settingsData = settingsResponse.data;

      setSmartproxy(settingsData.smartproxy);
      setOxylabs(settingsData.oxylabs);
      setGeoDetails(Array.isArray(settingsData.geoDetails) ? settingsData.geoDetails : []);
    } catch (error) {
      console.error('Error fetching proxy settings or configuration:', error);
      setError('Error fetching proxy settings or configuration.');
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = geoDetails.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className={styles.card2}>
      <h2>Geo Proxy Details</h2>
      {error && <p className={styles.errorMessage}>{error}</p>}

      {/* Credentials Row */}
      <div className={styles.credentialsRow}>
        <div className={styles.credentials}>
          <strong>Smartproxy</strong>
          <p><strong>Username:</strong> {showSmartproxyCredentials ? (smartproxy?.username || 'Not set') : '••••••••'}</p>
          <p><strong>Password:</strong> {showSmartproxyCredentials ? (smartproxy?.password || 'Not set') : '••••••••'}</p>
          <p><strong>Starting Port Gap:</strong> {smartproxy?.startingPortGap || 'Not set'}</p>
          <button
            className={styles.toggleButton}
            onClick={() => setShowSmartproxyCredentials(!showSmartproxyCredentials)}
          >
            {showSmartproxyCredentials ? 'Hide' : 'Show'} Credentials
          </button>
        </div>
        <div className={styles.credentials}>
          <strong>Oxylabs</strong>
          <p><strong>Username:</strong> {showOxylabsCredentials ? (oxylabs?.username || 'Not set') : '••••••••'}</p>
          <p><strong>Password:</strong> {showOxylabsCredentials ? (oxylabs?.password || 'Not set') : '••••••••'}</p>
          <p><strong>Starting Port Gap:</strong> {oxylabs?.startingPortGap || 'Not set'}</p>
          <button
            className={styles.toggleButton}
            onClick={() => setShowOxylabsCredentials(!showOxylabsCredentials)}
          >
            {showOxylabsCredentials ? 'Hide' : 'Show'} Credentials
          </button>
        </div>
      </div>

      {/* Geo Proxy Details Table */}
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Geo</th>
              <th>Smartproxy</th>
              <th>Oxylabs</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((detail, index) => (
              <tr key={index}>
                <td>{detail.geo}</td>
                <td>
                  <p><strong>Last Proxy:</strong> {detail.smartproxy?.lastProxy || 'N/A'}</p>
                  <p><strong>Next Proxy:</strong> {detail.smartproxy?.nextProxy || 'N/A'}</p>
                </td>
                <td>
                  <p><strong>Last Proxy:</strong> {detail.oxylabs?.lastProxy || 'N/A'}</p>
                  <p><strong>Next Proxy:</strong> {detail.oxylabs?.nextProxy || 'N/A'}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className={styles.pagination}>
        {Array.from({ length: Math.ceil(geoDetails.length / itemsPerPage) }, (_, i) => (
          <button
            key={i + 1}
            className={`${styles.pageButton} ${currentPage === i + 1 ? styles.activePageButton : ''}`}
            onClick={() => paginate(i + 1)}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Card2;
