import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './CreateTrackbox.module.css';

function TrackboxForm({ 
  initialData = {}, 
  templateName = "Trackbox", 
  onSuccess, 
  isEditing = false, 
  onDelete 
}) {
  const [formData, setFormData] = useState({
    name: '',
    template: templateName,
    apiKey: '',
    offerName: '',
    offerWebsite: '',
    domainName: '',
    EndPointURL: '',
    APIName: '',
    pullApiKey: '',
    username: '',
    sub: '',
    password: '',
    so: '',
    ai: '',
    ci: '',
    gi: '',
    customFields: {}, 
    ...initialData
  });
  const [isFormEditable, setIsFormEditable] = useState(!isEditing);
  const [message, setMessage] = useState({ text: '', type: '' });

  useEffect(() => {
    if (initialData) {
      setFormData((prevData) => ({ ...prevData, ...initialData }));
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = isEditing 
      ? `${process.env.REACT_APP_API_URL}/api/trackbox/networks/${formData._id}`
      : `${process.env.REACT_APP_API_URL}/api/trackbox/networks/create`;

    try {
      const response = await axios({
        method: isEditing ? 'put' : 'post',
        url: apiEndpoint,
        data: formData,
        headers: { 'Content-Type': 'application/json' }
      });

      alert(`${isEditing ? 'Trackbox updated' : 'Trackbox created'} successfully`);
      onSuccess(response.data);

      if (!isEditing) {
        const downloadResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trackbox/networks/${response.data._id}/download`,
          { responseType: 'blob' }
        );
        const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${response.data.APIName}.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setMessage({ text: 'Operation successful', type: 'success' });
    } catch (error) {
      console.error('Error processing request:', error.response ? error.response.data : error.message);
      setMessage({ text: 'Error processing request', type: 'error' });
    }
  };

  const handleDelete = async () => {
    if (onDelete && formData._id) {
      await onDelete(formData._id);
    }
  };

  return (
    <div className={styles.card2}>
      {message.text && <div className={`${styles.message} ${styles[message.type]}`}>{message.text}</div>}
      <h2>Please Enter Your API Credentials</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        {[
          { label: "Name", name: "name", required: true },
          { label: "API Key", name: "apiKey", required: true },
          { label: "Offer Name", name: "offerName", required: true },
          { label: "Offer Website", name: "offerWebsite" },
          { label: "Domain Name", name: "domainName" },
          { label: "EndPoint URL", name: "EndPointURL" },
          { label: "API Name", name: "APIName" },
          { label: "Pull API Key", name: "pullApiKey" },
          { label: "Username", name: "username" },
          { label: "Sub", name: "sub" },
          { label: "Password", name: "password", type: "password" },
          { label: "SO", name: "so" },
          { label: "AI", name: "ai" },
          { label: "CI", name: "ci" },
          { label: "GI", name: "gi" },
        ].map(({ label, name, type = "text", required }) => (
          <div key={name} className={styles.formGroup}>
            <label>{label}:</label>
            <input
              type={type}
              name={name}
              value={formData[name]}
              onChange={handleChange}
              required={required}
              disabled={!isFormEditable}
            />
          </div>
        ))}
        <div className={styles.formActions}>
          {isEditing && !isFormEditable ? (
            <button type="button" className={styles.editButton} onClick={() => setIsFormEditable(true)}>
              Edit
            </button>
          ) : (
            <button type="submit" className={styles.submitButton}>
              {isEditing ? 'Save Changes' : 'Create'}
            </button>
          )}

          {isEditing && (
            <button type="button" className={styles.deleteButton} onClick={handleDelete}>
              Delete
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default TrackboxForm;
