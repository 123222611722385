import axios from './axiosConfig';

// Network leads and FTDs
export const fetchLeads = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/track/leads', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching leads:', error);
    throw error;
  }
};

export const fetchFTDs = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/track/ftds', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching FTDs:', error);
    throw error;
  }
};

export const fetchCRMData = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/track/leads/crm', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching CRM data:', error);
    throw error;
  }
};

export const fetchLeadsData = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/track/leads/leads', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching leads data:', error);
    throw error;
  }
};

export const fetchCRMMetrics = async (dateRange) => {
  try {
    const response = await axios.get(`/crm-metrics`, {
      params: { dateRange },
    });
    return response.data; // Adjust based on your API's response structure
  } catch (error) {
    console.error('Error fetching CRM metrics:', error);
    throw error;
  }
};

export const fetchConversionRate = async (dateRange) => {
  try {
    const response = await axios.get(`/conversonrate`, {
      params: { dateRange },
    });
    return response.data; // Adjust based on your API's response structure
  } catch (error) {
    console.error('Error fetching CRM metrics:', error);
    throw error;
  }
};


export const fetchRevenue = async (dateRange) => {
  try {
    const response = await axios.get(`/revenue`, {
      params: { dateRange },
    });
    return response.data; // Adjust based on your API's response structure
  } catch (error) {
    console.error('Error fetching CRM metrics:', error);
    throw error;
  }
};

export const fetchFTDSData = async (dateRange, leadType) => {
  try {
    const response = await axios.get('/api/track/ftds', {
      params: { dateRange, leadType }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching FTDs data:', error);
    throw error;
  }
};

export const fetchCombinedLeads = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/track/leads/combined_leads', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching combined leads:', error);
    throw error;
  }
};

export const fetchLeadCount = async (dateRange) => {
  try {
    // Update the URL based on your backend route for leads count
    const response = await axios.get('/api/track/counts/leads', { params: { dateRange } });
    return response.data;  // Return the data containing the lead count
  } catch (error) {
    console.error('Error fetching lead count:', error);
    throw error;  // Rethrow the error if needed
  }
};

// Fetch the count of FTDs based on the dateRange
export const fetchFTDCount = async (dateRange) => {
  try {
    // Update the URL based on your backend route for FTD count
    const response = await axios.get('/api/track/counts/ftds', { params: { dateRange } });
    return response.data;  // Return the data containing the FTD count
  } catch (error) {
    console.error('Error fetching FTD count:', error);
    throw error;  // Rethrow the error if needed
  }
};

// New function to fetch FTDs combined with CRM and lead data
export const fetchFTDsWithCRMLead = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/track/ftds/ftds_with_crm_lead', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching FTDs with CRM and Lead data:', error);
    throw error;
  }
};

// Trackbox leads and FTDs
export const fetchTrackboxLeads = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/trackbox/leads', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox leads:', error);
    throw error;
  }
};

export const fetchTrackboxFTDs = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/trackbox/ftds', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox FTDs:', error);
    throw error;
  }
};

export const fetchTrackboxCRMData = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/trackbox/leads/crm', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox CRM data:', error);
    throw error;
  }
};


export const fetchTrackboxLeadsData = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/trackbox/leads/leads', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox leads data:', error);
    throw error;
  }
};

export const fetchTrackboxFTDSData = async (dateRange, leadType) => {
  try {
    const response = await axios.get('/api/trackbox/ftds', {
      params: { dateRange, leadType }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox FTDs data:', error);
    throw error;
  }
};

export const fetchTrackboxCombinedLeads = async (dateRange = '', leadType = '', geo = '') => {
  try {
    const response = await axios.get('/api/trackbox/leads/combined_leads', { params: { dateRange, leadType, geo } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox combined leads:', error);
    throw error;
  }
};

// Fetch Trackbox FTD with CRM data
export const fetchTrackboxFTDsWithCRM = async (dateRange = '', ftdType = '') => {
  try {
    const response = await axios.get('/api/trackbox/ftds/with_crm', { params: { dateRange, ftdType } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox FTD with CRM data:', error);
    throw error;
  }
};

// Fetch New Leads
export const fetchNewLeads = async (dateRange = '', network = '') => {
  try {
    const response = await axios.get('/api/track/leads/new_leads', { params: { dateRange, network } });
    return response.data;
  } catch (error) {
    console.error('Error fetching New Leads:', error);
    throw error;
  }
};


// Fetch New FTDs
export const fetchNewFtds = async (dateRange = '', network = '') => {
  try {
    const response = await axios.get('/api/track/ftds/new_ftd', { params: { dateRange, network } });
    return response.data;
  } catch (error) {
    console.error('Error fetching New FTDs:', error);
    throw error;
  }
};

export const fetchTrackboxLeadCount = async (dateRange) => {
  try {
    const response = await axios.get('/api/trackbox/counts/leads/count', { params: { dateRange } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox lead count:', error);
    throw error;
  }
};

export const fetchTrackboxFTDCount = async (dateRange) => {
  try {
    const response = await axios.get('/api/trackbox/counts/ftds/count', { params: { dateRange } });
    return response.data;
  } catch (error) {
    console.error('Error fetching Trackbox FTD count:', error);
    throw error;
  }
};

