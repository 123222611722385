import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreateHM.module.css';

function CreateHM({ onCreateNetwork = () => {} }) {
    const [formData, setFormData] = useState({
        name: '',
        apiToken: '',
        offerName: '',
        offerWebsite: '',
        domainName: '',
        apiName: '',
        endpointUrl: '',
        linkId: '',
        source: '',
        affiliateId: '',
        offerId: '',
        template: 'HM' // Default template set to 'HM'
    });

    const [message, setMessage] = useState({ text: '', type: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable submit button while processing
        setMessage({ text: 'Creating HM network...', type: 'info' });

        const createEndpoint = `${process.env.REACT_APP_API_URL}/api/hm/networks/create`;

        try {
            // Step 1: Create the network
            const createResponse = await axios.post(createEndpoint, formData, {
                headers: { 'Content-Type': 'application/json' }
            });

            const newNetwork = createResponse.data;
            setMessage({ text: 'HM network created successfully!', type: 'success' });

            // Notify parent component of successful creation
            onCreateNetwork(newNetwork);

            // Step 2: Download the network files using the created network ID
            const downloadEndpoint = `${process.env.REACT_APP_API_URL}/api/hm/networks/${newNetwork._id}/download`;
            const downloadResponse = await axios.get(downloadEndpoint, { responseType: 'blob' });

            // Trigger file download
            const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${newNetwork.apiName || 'hm_network'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Reset form and message
            setFormData({
                name: '',
                apiToken: '',
                offerName: '',
                offerWebsite: '',
                domainName: '',
                apiName: '',
                endpointUrl: '',
                linkId: '',
                source: '',
                affiliateId: '',
                offerId: '',
                template: 'HM'
            });
            setMessage({ text: '', type: '' });

        } catch (error) {
            console.error('Error creating or downloading HM network:', error);
            setMessage({ text: 'Error creating or downloading HM network. Please try again.', type: 'error' });
        } finally {
            setIsSubmitting(false); // Re-enable submit button after processing
        }
    };

    return (
        <div className={styles.card}>
            {message.text && (
                <div className={`${styles.message} ${styles[message.type]}`}>
                    {message.text}
                </div>
            )}
            <h2>Please Enter Your API Credentials</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                {[
                    { label: "Network Name", name: "name", required: true },
                    { label: "API Token", name: "apiToken", required: true },
                    { label: "Offer Name", name: "offerName", required: true },
                    { label: "Offer Website", name: "offerWebsite", required: true },
                    { label: "Domain Name", name: "domainName", required: true },
                    { label: "API Name", name: "apiName", required: true },
                    { label: "Endpoint URL", name: "endpointUrl", required: true },
                    { label: "Link ID", name: "linkId" },
                    { label: "Source", name: "source" },
                    { label: "Affiliate ID", name: "affiliateId" },
                    { label: "Offer ID", name: "offerId" }
                ].map(({ label, name, required }) => (
                    <div className={styles.formRow} key={name}>
                        <label>{label}:</label>
                        <input
                            type="text"
                            name={name}
                            value={formData[name]}
                            onChange={handleChange}
                            required={required}
                        />
                    </div>
                ))}
                <div className={styles.formActions}>
                    <button type="submit" className={styles.createButton} disabled={isSubmitting}>
                        {isSubmitting ? 'Creating...' : 'Create'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default CreateHM;
