import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import Card1 from './Card1';
import Card2 from './Card2';
import axios from 'axios';
import styles from './UserAgentSettings.uas.module.css';

function UserAgentSettings() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 25; // Pagination: 25 rows per page

  // Fetch user agent data from the backend
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-agents/data`);
      // Filter out rows where `used` is true
      const filteredData = response.data.filter((row) => !row.used);
      setData(filteredData);
    } catch (error) {
      console.error('Error fetching user agents:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDataUpdate = useCallback(() => {
    fetchData();
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(data.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.userAgentSettingsContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
        <h2 className={styles.heading}>User-Agent Settings</h2>
        <div className={styles.cardContainer}>
          {/* Card1 for File Upload */}
          <div className={styles.card1}>
            <Card1 onUpload={handleDataUpdate} />
          </div>

          {/* Card2 for Table Display */}
          <div className={styles.card2}>
            <Card2 data={paginatedData} />
          </div>
        </div>

        {/* Pagination */}
        <div className={styles.pagination}>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`${styles.pageButton} ${currentPage === index + 1 ? styles.activePageButton : ''}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserAgentSettings;
