import React, { useState, useEffect } from "react";
import Sidebar from "../dashboard/Sidebar";
import styles from "./Leads2.l2.module.css";
import { fetchNewLeads } from "../utils/Trackingapi"; // Import the fetch function

const Leads2 = () => {
  const [dateRange, setDateRange] = useState("today");
  const [leads, setLeads] = useState([]); // Initialize leads data
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Fetch leads based on the selected date range
  const getLeadsData = async () => {
    try {
      const data = await fetchNewLeads(dateRange);
      setLeads(Array.isArray(data) ? data : []); // Ensure data is an array
      setCurrentPage(1); // Reset pagination when fetching new data
    } catch (error) {
      console.error("Error fetching leads:", error);
      setLeads([]); // Clear leads on error
    }
  };

  // Fetch leads whenever the date range changes
  useEffect(() => {
    getLeadsData();
  }, [dateRange]);

  // Pagination logic
  const totalPages = Math.ceil(leads.length / itemsPerPage);
  const displayedLeads = leads.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.leads2Container}>
      <Sidebar className={styles.leads2Sidebar} />
      <div className={styles.leads2Main}>
        <h2 className={styles.leads2Heading}>Leads Management</h2>

        {/* Date Filters */}
        <div className={styles.settingsCard}>
          <div className={styles.settingsCardSection}>
            <h3>Date Filters</h3>
            <div className="filter-buttons">
              {[
                { label: "Today", value: "today" },
                { label: "Yesterday", value: "yesterday" },
                { label: "This Week", value: "thisWeek" },
                { label: "Last Week", value: "lastWeek" },
                { label: "This Month", value: "thisMonth" },
                { label: "Last Month", value: "lastMonth" },
                { label: "Custom", value: "custom" },
              ].map((range) => (
                <button
                  key={range.value}
                  className={`button button-primary ${
                    dateRange === range.value ? "active" : ""
                  }`}
                  onClick={() => setDateRange(range.value)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Leads Table */}
        <div className={styles.settingsCard}>
          <h3>Leads</h3>
          <table className={styles.leadsTable}>
            <thead>
              <tr>
                <th>Network Name</th>
                <th>IP Address</th>
                <th>Country</th>
                <th>Status</th>
                <th>Campaign Name</th>
                <th>Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {displayedLeads.length > 0 ? (
                displayedLeads.map((lead, index) => (
                  <tr key={index}>
                    <td>{lead.network || "N/A"}</td>
                    <td>{lead.ip || lead.rawData?.customerData?.ip ||"N/A"}</td>
                    <td>{lead.rawData?.countryName || lead.rawData?.customerData?.countrcode || "N/A"}</td>
                    <td>{lead.rawData?.saleStatus || lead.rawData?.customerData?.call_status || "N/A"}</td>
                    <td>{lead.rawData?.campaignName || lead.rawData?.tracking?.so || "N/A"}</td>
                    <td>{new Date(lead.timestamp).toLocaleDateString()}</td>
                    <td>{new Date(lead.timestamp).toLocaleTimeString()}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className={styles.noData}>
                    No leads available for the selected date range.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className={styles.pagination}>
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={currentPage === i + 1 ? styles.active : ""}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leads2;
