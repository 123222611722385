import React, { useState } from "react";
import Sidebar from "../dashboard/Sidebar";
import Card2 from "./Card2"; // Original Card2 functionality
import styles from "./Networking.Networks.module.css";

const Networks = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  return (
    <div className={styles.networksContainer}>
      <Sidebar />
      <div className={styles.networksMain}>
        <h2 className={styles.networksHeading}>Networks Management</h2>

        {/* Template Selection */}
        <div className={styles.settingsCard}>
          <Card2
            selectedItem={null} // Pass selected item for editing (if required)
            onCreateItem={(newItem) => console.log("Network Created:", newItem)} // Handle network creation
            onUpdateItem={(updatedItem) => console.log("Network Updated:", updatedItem)} // Handle network update
            onDeleteItem={(id) => console.log("Network Deleted:", id)} // Handle network deletion
          />
        </div>
      </div>
    </div>
  );
};

export default Networks;
