import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';

function Sidebar() {
  const [showScheduleSubmenu, setShowScheduleSubmenu] = useState(false);
  const [user, setUser] = useState({ firstName: '', email: '' });

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/api/auth/user-info', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };

    fetchUserInfo();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const toggleSubmenu = (setSubmenuState, currentState) => {
    setSubmenuState(!currentState);
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <div className="menu-card">
          <NavLink to="/dashboard" className="menu-button" activeClassName="active">
            Dashboard
          </NavLink>

          {/* Networking Button */}
          <NavLink to="/Networking" className="menu-button" activeClassName="active">
            Networking
          </NavLink>

          {/* Schedule Section */}
          <div className="menu-item">
            <div
              className="menu-button"
              onClick={() => toggleSubmenu(setShowScheduleSubmenu, showScheduleSubmenu)}
              aria-expanded={showScheduleSubmenu}
            >
              Schedule
              <span>{showScheduleSubmenu ? '▲' : '▼'}</span>
            </div>
            <div className={`submenu ${showScheduleSubmenu ? 'open' : ''}`}>
              <NavLink to="/schedule" className="submenu-button">
                Schedule Leads
              </NavLink>
              <NavLink to="/schedule/UploadLeads" className="submenu-button">
                UploadLeads
              </NavLink>
              <NavLink to="/schedule/ScheduledTasks" className="submenu-button">
                Scheduled Tasks
              </NavLink>
              <NavLink to="/schedule/user-agent-settings" className="submenu-button">
                User Agent Settings
              </NavLink>
              <NavLink to="/schedule/ProxySettings" className="submenu-button">
                Proxy Settings
              </NavLink>
              <NavLink to="/schedule/TaskResult" className="submenu-button">
                Task Results
              </NavLink>
            </div>
          </div>

          <NavLink to="/offers" className="menu-button">
            Offers
          </NavLink>
          <NavLink to="/leads2" className="menu-button">
            Leads
          </NavLink>
          <NavLink to="/CRM" className="menu-button">
            CRM
          </NavLink>
          <NavLink to="/ftds" className="menu-button">
            FTDS
          </NavLink>
        </div>

        <div className="user-profile-card">
          <div className="user-icon">👤</div>
          <div className="user-details">
            <p>{user.firstName}</p>
            <p>{user.email}</p>
          </div>
        </div>

        <div className="logout-card">
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
