import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import styles from './ScheduledTasks.st.module.css';
import axios from '../../utils/axiosConfig';
import { formatDistanceToNow } from 'date-fns';

function ScheduledTasks() {
  const [scheduledTasks, setScheduledTasks] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20; // Show 20 tasks per page

  useEffect(() => {
    const fetchScheduledTasks = async () => {
      try {
        const response = await axios.get('/api/schedule/tasks');
        setScheduledTasks(response.data);
      } catch (error) {
        console.error('Error fetching scheduled tasks:', error);
      }
    };
    fetchScheduledTasks();
  }, []);

  const getCountdown = (startTime) => {
    const start = new Date(startTime);
    return formatDistanceToNow(start, { addSuffix: true });
  };

  const handleRowCheckboxClick = (rowNumber) => {
    if (selectedRows.includes(rowNumber)) {
      setSelectedRows(selectedRows.filter((row) => row !== rowNumber));
    } else {
      setSelectedRows([...selectedRows, rowNumber]);
    }
    setSelectedAll(false);
  };

  const handleExecuteTask = async () => {
    if (selectedRows.length === 0 && !selectedAll) return;
  
    try {
      const tasksToExecute = selectedAll
        ? scheduledTasks.map((task) => task._id)
        : selectedRows.map((rowNumber) => scheduledTasks[rowNumber]._id);
  
      // Execute each task by making individual API calls
      const executionPromises = tasksToExecute.map((taskId) =>
        axios.post(`/api/schedule/schedule-now/${taskId}`)
      );
  
      // Wait for all tasks to be scheduled
      await Promise.all(executionPromises);
  
      alert('Task(s) executed successfully!');
    } catch (error) {
      console.error('Error executing task:', error);
      alert('An error occurred while scheduling tasks.');
    }
  };
  

  const handlePauseTask = async () => {
    if (selectedRows.length === 0 && !selectedAll) return;
    try {
      const tasksToPause = selectedAll
        ? scheduledTasks.map((task) => task._id)
        : selectedRows.map((rowNumber) => scheduledTasks[rowNumber]._id);
      await axios.post('/api/schedule/pause-tasks', { taskIds: tasksToPause });
      alert('Task(s) paused successfully!');
    } catch (error) {
      console.error('Error pausing task:', error);
    }
  };

  const handleSelectAll = () => {
    if (!selectedAll) {
      setSelectedAll(true);
      setSelectedRows(scheduledTasks.map((_, index) => index));
    } else {
      setSelectedAll(false);
      setSelectedRows([]);
    }
  };

  // Pagination calculations
  const totalPages = Math.ceil(scheduledTasks.length / itemsPerPage);
  const displayedTasks = scheduledTasks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedAll(false); // Deselect all when changing pages
    setSelectedRows([]); // Clear selected rows when switching pages
  };

  return (
    <div className={styles.scheduledTasksContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
        <h2 className={styles.heading}>Scheduled Tasks</h2>
        <div className={styles.buttonContainer}>
          <button
            onClick={handleExecuteTask}
            disabled={selectedRows.length === 0 && !selectedAll}
            className={styles.taskButton}
          >
            Execute Task
          </button>
          <button
            onClick={handlePauseTask}
            disabled={selectedRows.length === 0 && !selectedAll}
            className={`${styles.taskButton} ${styles.pauseButton}`}
          >
            Pause Task
          </button>
        </div>
        {scheduledTasks.length > 0 ? (
          <>
            <table className={styles.taskTable}>
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" checked={selectedAll} onChange={handleSelectAll} />
                  </th>
                  <th>#</th>
                  <th>Task Name</th>
                  <th>Status</th>
                  <th>Time to Start</th>
                  <th>Offer Link</th>
                  <th>Country Code</th>
                  <th>Row Range</th>
                </tr>
              </thead>
              <tbody>
                {displayedTasks.map((task, index) => (
                  <tr
                    key={task._id}
                    className={
                      selectedRows.includes((currentPage - 1) * itemsPerPage + index) || selectedAll
                        ? styles.selectedRow
                        : ''
                    }
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={
                          selectedRows.includes((currentPage - 1) * itemsPerPage + index) ||
                          selectedAll
                        }
                        onChange={() =>
                          handleRowCheckboxClick((currentPage - 1) * itemsPerPage + index)
                        }
                      />
                    </td>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{task.formData.taskName}</td>
                    <td>{task.status}</td>
                    <td>{getCountdown(task.startTime)}</td>
                    <td>{task.formData.offerLink}</td>
                    <td>{task.formData.countryCode}</td>
                    <td>{task.formData.rowRange}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.pagination}>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  className={`${styles.pageButton} ${
                    currentPage === i + 1 ? styles.activePageButton : ''
                  }`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className={styles.noTasksMessage}>No scheduled tasks found.</p>
        )}
      </div>
    </div>
  );
}

export default ScheduledTasks;
