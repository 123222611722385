import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Sidebar component
import './Dashboard.css'; // Shared CSS for styling

// Import API calls (replace with your actual API functions)
import {
  fetchLeadCount,
  fetchFTDCount,
  fetchRevenue,
  fetchConversionRate,
} from '../utils/Trackingapi'; // Replace with actual file path

// Import Chart components
import BarChart from './BarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';

const Dashboard = () => {
  const [dateRange, setDateRange] = useState('today'); // State for date filter selection
  const [metrics, setMetrics] = useState({
    crmLeads: 0,
    ftds: 0,
    revenue: '$0',
    conversions: '0%',
  });

  const tasks = [
    { status: 'In Progress', count: 5, className: 'in-progress' },
    { status: 'Pending', count: 3, className: 'pending' },
    { status: 'Completed', count: 7, className: 'completed' },
  ];

  const activities = [
    { title: 'Top Offer', description: 'Campaign A' },
    { title: 'Recent Activity', description: 'User logged in' },
  ];

  useEffect(() => {
    const updateMetrics = async () => {
      try {
        const [leadsData, ftdsData, revenueData, conversionsData] = await Promise.all([
          fetchLeadCount(dateRange),
          fetchFTDCount(dateRange),
          fetchRevenue(dateRange),
          fetchConversionRate(dateRange),
        ]);

        const leadCount = leadsData.count || 0;
        const ftdCount = ftdsData.count || 0;
        const conversionRate = leadCount > 0 ? (ftdCount / leadCount) * 100 : 0;

        setMetrics({
          crmLeads: leadCount,
          ftds: ftdCount,
          revenue: `$${(ftdCount * 1000).toLocaleString()}`,
          conversions: `${conversionRate.toFixed(2)}%`,
        });
      } catch (error) {
        console.error('Error fetching metrics:', error);
      }
    };

    updateMetrics();
  }, [dateRange]);

  const handleApplyFilters = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const chartData = {
    labels: ['Leads', 'FTDs', 'Revenue', 'Conversion Rate'],
    values: [
      metrics.crmLeads,
      metrics.ftds,
      parseFloat(metrics.revenue.replace(/[$,]/g, '')),
      parseFloat(metrics.conversions),
    ],
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-main">
        <h2 className="dashboard-heading">Dashboard</h2>

        {/* Date Filters */}
        <div className="settings-card">
          <div className="settings-card-section">
            <h3>Date Filters</h3>
            <div className="filter-buttons">
              {[
                { label: 'Today', value: 'today' },
                { label: 'Yesterday', value: 'yesterday' },
                { label: 'This Week', value: 'thisWeek' },
                { label: 'Last Week', value: 'lastWeek' },
                { label: 'This Month', value: 'thisMonth' },
                { label: 'Last Month', value: 'lastMonth' },
                { label: 'Custom', value: 'custom' },
              ].map((range) => (
                <button
                  key={range.value}
                  className={`button button-primary ${dateRange === range.value ? 'active' : ''}`}
                  onClick={() => handleApplyFilters(range.value)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* CRM Metrics */}
        <div className="settings-card">
          <div className="settings-card-section">
            <h3>CRM Metrics</h3>
            <div className="grid">
              {[{ title: 'CRM Leads', value: metrics.crmLeads },
                { title: 'FTDs', value: metrics.ftds },
                { title: 'Revenue', value: metrics.revenue },
                { title: 'Conversions', value: metrics.conversions }].map((metric) => (
                <div key={metric.title} className="settings-card-item">
                  <h4>{metric.title}</h4>
                  <p>{metric.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Task Section */}
        <div className="settings-card">
          <div className="settings-card-section">
            <h3>Schedule</h3>
            <div className="grid">
              {tasks.map((task) => (
                <div key={task.status} className={`settings-card-item ${task.className}`}>
                  <h4>{task.status}</h4>
                  <p>{task.count}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div className="settings-card">
          <div className="settings-card-section">
            <h3>Charts</h3>
            <div className="charts-section">
              <div className="chart-container">
                <BarChart data={chartData} />
              </div>
              <div className="chart-container">
                <LineChart data={chartData} />
              </div>
              <div className="chart-container">
                <PieChart data={chartData} />
              </div>
            </div>
          </div>
        </div>

        {/* Activity Stream */}
        <div className="settings-card">
          <div className="settings-card-section">
            <h3>Activity Stream</h3>
            <div className="grid">
              {activities.map((activity, index) => (
                <div key={index} className="settings-card-item">
                  <h4>{activity.title}</h4>
                  <p>{activity.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
