import React, { useEffect, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, PointElement } from 'chart.js';

// Register necessary components from Chart.js
ChartJS.register(ArcElement, Title, Tooltip, Legend, PointElement);

const PieChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chartInstance?.destroy(); // Destroy existing chart before creating a new one
    }
  }, [data]); // Re-render the chart when `data` changes

  const chartData = {
    labels: data.labels,  // Labels for each segment
    datasets: [
      {
        data: data.values,  // Data for the segments
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie ref={chartRef} data={chartData} />;
};

export default PieChart;
