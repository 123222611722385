import React from 'react';
import Sidebar from '../../dashboard/Sidebar';
import MainContent from './MainContent';
import styles from './UploadLeads.ul.module.css';

function UploadLeads() {
  return (
    <div className={styles.uploadLeadsContainer}>
      <Sidebar />
      <div className={styles.uploadLeadsMain}>
        <h2 className={styles.uploadLeadsHeading}>Upload Leads</h2>
        <MainContent />
      </div>
    </div>
  );
}

export default UploadLeads;
