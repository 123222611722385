import React, { useState, useEffect } from "react";
import Sidebar from "../dashboard/Sidebar";
import styles from "./FTDS.FTDS.module.css";
import { fetchNewFtds } from "../utils/Trackingapi"; // Import the fetch function

const FTDS = () => {
  const [dateRange, setDateRange] = useState("today");
  const [ftds, setFTDS] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  // Fetch FTDS data based on date range
  const getFTDSData = async () => {
    try {
      const data = await fetchNewFtds(dateRange);
      setFTDS(Array.isArray(data) ? data : []);
      setCurrentPage(1); // Reset pagination when new data is fetched
    } catch (error) {
      console.error("Error fetching FTDS data:", error);
      setFTDS([]);
    }
  };

  // Trigger data fetch whenever the date range changes
  useEffect(() => {
    getFTDSData();
  }, [dateRange]);

  // Pagination logic
  const totalPages = Math.ceil(ftds.length / itemsPerPage);
  const displayedFTDS = ftds.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className={styles.ftdsContainer}>
      <Sidebar />
      <div className={styles.ftdsMain}>
        <h2 className={styles.ftdsHeading}>FTDS Management</h2>

        {/* Date Filters */}
        <div className={styles.settingsCard}>
          <div className={styles.settingsCardSection}>
            <h3>Date Filters</h3>
            <div className="filter-buttons">
              {[
                { label: "Today", value: "today" },
                { label: "Yesterday", value: "yesterday" },
                { label: "This Week", value: "thisWeek" },
                { label: "Last Week", value: "lastWeek" },
                { label: "This Month", value: "thisMonth" },
                { label: "Last Month", value: "lastMonth" },
                { label: "Custom", value: "custom" },
              ].map((range) => (
                <button
                  key={range.value}
                  className={`button button-primary ${
                    dateRange === range.value ? "active" : ""
                  }`}
                  onClick={() => setDateRange(range.value)}
                >
                  {range.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* FTDS Table */}
        <div className={styles.settingsCard}>
          <h3>FTDS</h3>
          <table className={styles.ftdsTable}>
            <thead>
              <tr>
                <th>Network Name</th>
                <th>IP Address</th>
                <th>FTDS Amount</th>
                <th>Country</th>
                <th>Status</th>
                <th>Date</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {displayedFTDS.length > 0 ? (
                displayedFTDS.map((ftd, index) => {
                  // Normalize the FTDS amount and status fields
                  const ftdsAmount = ftd.amount || ftd.FTDSAmount || ftd.rawData?.amount || "N/A";
                  const status = ftd.status || ftd.rawData?.status || "N/A";

                  return (
                    <tr key={index}>
                      <td>{ftd.network || "N/A"}</td>
                      <td>{ftd.ip || ftd.rawData?.customerData?.ip ||"N/A"}</td>
                      <td>{ftds.Amount ||  "250"}</td>
                      <td>{ftd.country || ftd.rawData?.country || ftd.rawData?.countryName || ftd.rawData?.customerData?.countrcode || "N/A"}</td>
                      <td>{ftd.rawData?.customerData?.call_status || "Depositor"}</td>
                      <td>{new Date(ftd.timestamp).toLocaleDateString()}</td>
                      <td>{new Date(ftd.timestamp).toLocaleTimeString()}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className={styles.noData}>
                    No FTDS data available for the selected date range.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className={styles.pagination}>
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => handlePageChange(i + 1)}
                  className={currentPage === i + 1 ? styles.active : ""}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FTDS;
