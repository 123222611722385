import React, { useState, useEffect } from "react";
import Sidebar from "../dashboard/Sidebar";
import axios from "../utils/axiosConfig";
import styles from "./Offers.Offers.module.css"; // Corrected CSS import path

const Offers = () => {
  const [offers, setOffers] = useState([]);
  const [trackboxOffers, setTrackboxOffers] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Fetch offers and trackbox offers
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const [regularOffersResponse, trackboxOffersResponse] = await Promise.all([
          axios.get("api/offers"),
          axios.get("api/trackboxoffers"),
        ]);

        setOffers(regularOffersResponse.data);
        setTrackboxOffers(trackboxOffersResponse.data);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error fetching offers data:", error);
        setOffers([]);
        setTrackboxOffers([]);
      }
    };

    fetchOffers();
  }, []);

  // Filter offers based on the search input
  const filteredOffers = offers.filter((offer) =>
    offer.offerName?.toLowerCase().includes(search.toLowerCase())
  );

  const filteredTrackboxOffers = trackboxOffers.filter((offer) =>
    offer.offerName?.toLowerCase().includes(search.toLowerCase())
  );

  // Combine and paginate offers
  const totalOffers = [...filteredOffers, ...filteredTrackboxOffers];
  const totalPages = Math.ceil(totalOffers.length / itemsPerPage);
  const displayedOffers = totalOffers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page changes
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCopyLink = (offer) => {
    const uniqueClickId = "clickid_" + Math.random().toString(36).substr(2, 9);
    const offerLinkWithClickId = offer.offerLink.replace("{clickid}", uniqueClickId);

    navigator.clipboard.writeText(offerLinkWithClickId).then(() => {
      alert("Offer link copied to clipboard");
    });
  };

  return (
    <div className={styles.offersContainer}>
      <Sidebar />
      <div className={styles.offersMain}>
        <h2 className={styles.offersHeading}>Offers Management</h2>

        {/* Search Bar */}
        <div className={styles.settingsCard}>
          <input
            type="text"
            placeholder="Search Offers"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={styles.searchBox}
          />
        </div>

        {/* Offers List */}
        <div className={styles.settingsCard}>
          <h3>Offers</h3>
          <div className={styles.offersList}>
            {displayedOffers.length > 0 ? (
              displayedOffers.map((offer, index) => (
                <div key={offer._id || index} className={styles.offerItem}>
                  <div className={styles.offerButton}>
                    {offer.offerName || "Unnamed Offer"}
                  </div>
                  <div className={styles.offerDetails}>
                    <p>
                      <strong>Network:</strong> {offer.network?.name || "N/A"}
                    </p>
                    <p>
                      <strong>Details:</strong> {offer.offerDetails || "N/A"}
                    </p>
                    <p>
                      <strong>Offer Link:</strong>{" "}
                      {offer.offerLink.replace("{clickid}", "<clickid>")}
                    </p>
                    <button
                      onClick={() => handleCopyLink(offer)}
                      className={styles.copyButton}
                    >
                      Copy Offer Link
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noData}>No offers found</div>
            )}
          </div>
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className={styles.pagination}>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={currentPage === i + 1 ? styles.activePageButton : ""}
              >
                {i + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Offers;
