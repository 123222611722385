// src/components/create/CreateGetLinked.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreateGetLinked.module.css';

const splashAdsFields = [
  'apiKey', 'offerName', 'offerWebsite', 'domainName', 'EndPointURL', 'APIName', 
  'custom1', 'custom2', 'custom3', 'custom4', 'custom5'
];

function CreateGetLinked({ onCreateNetwork }) {
  const [formData, setFormData] = useState({
    name: '',
    template: 'SplashAds', // Fixed template set to 'SplashAds'
    apiKey: '',
    offerName: '',
    offerWebsite: '',
    domainName: '',
    EndPointURL: '',
    APIName: '',
    custom1: '',
    custom2: '',
    custom3: '',
    custom4: '',
    custom5: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Endpoint for creating networks
    const createEndpoint = `${process.env.REACT_APP_API_URL}/api/networks/create`;

    try {
      // First, create the network
      const createResponse = await axios.post(createEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const newNetwork = createResponse.data;

      // Notify parent component of successful creation
      if (onCreateNetwork) onCreateNetwork(newNetwork);

      // Then, trigger download using the network's ID
      const downloadEndpoint = `${process.env.REACT_APP_API_URL}/api/networks/${newNetwork._id}/download`;
      const downloadResponse = await axios.get(downloadEndpoint, { responseType: 'blob' });

      const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${newNetwork.APIName || 'network_template'}.zip`;
      link.click();

    } catch (error) {
      console.error('Error creating or downloading network', error);
    }
  };

  return (
    <div className={styles.card2}>
      <h2>Please Enter Your API Credentials</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Network Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Render form fields specific to SplashAds template */}
        {splashAdsFields.map(field => (
          <div className={styles.formGroup} key={field}>
            <label>{field}:</label>
            <input
              type="text"
              name={field}
              value={formData[field] || ''}
              onChange={handleChange}
            />
          </div>
        ))}

        <div className={styles.formActions}>
          <button type="submit" className={styles.createButton}>Create</button>
        </div>
      </form>
    </div>
  );
}

export default CreateGetLinked;
