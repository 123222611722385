import React, { useState, useEffect } from 'react';
import Sidebar from '../../dashboard/Sidebar';
import styles from './TaskResult.tr.module.css';
import axios from '../../utils/axiosConfig';
import { formatDistanceToNow } from 'date-fns';

function TaskResult() {
  const [completedTasks, setCompletedTasks] = useState([]);
  const [selectedTaskResults, setSelectedTaskResults] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [loadingResults, setLoadingResults] = useState(false);
  const [error, setError] = useState(null);
  const [expandedTask, setExpandedTask] = useState(null); // Tracks expanded task

  // Sorting State
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      try {
        const response = await axios.get('/api/schedule/tasks');
        const completed = response.data.filter((task) => task.status === 'completed');
        setCompletedTasks(completed);
      } catch (err) {
        console.error('Error fetching completed tasks:', err);
        setError(err.response?.data?.message || 'Failed to fetch completed tasks.');
      } finally {
        setLoadingTasks(false);
      }
    };

    fetchCompletedTasks();
  }, []);

  const totalPages = Math.ceil(completedTasks.length / itemsPerPage);

  // Sorting logic
  const sortTasks = (tasks) => {
    if (!sortConfig.key) return tasks;

    return [...tasks].sort((a, b) => {
      const aValue = a.formData?.[sortConfig.key] || a[sortConfig.key] || '';
      const bValue = b.formData?.[sortConfig.key] || b[sortConfig.key] || '';
      const direction = sortConfig.direction === 'asc' ? 1 : -1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * direction;
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        return (aValue - bValue) * direction;
      } else {
        return 0;
      }
    });
  };

  const sortedTasks = sortTasks(completedTasks);

  const displayedTasks = sortedTasks.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const fetchTaskResults = async (taskId) => {
    setLoadingResults(true);
    setError(null);

    try {
      const response = await axios.get(`/api/schedule/tasks/task-results/${taskId}`);
      setSelectedTaskResults(response.data || []); // Default to empty array if no results
    } catch (err) {
      console.error('Error fetching task results:', err);
      setError(err.response?.data?.message || 'Failed to fetch task results.');
    } finally {
      setLoadingResults(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  return (
    <div className={styles.taskResultContainer}>
      <Sidebar />
      <div className={styles.mainContent}>
        <h2 className={styles.heading}>Completed Tasks</h2>

        {loadingTasks ? (
          <p className={styles.message}>Loading tasks...</p>
        ) : error ? (
          <p className={styles.message}>Error: {error}</p>
        ) : completedTasks.length === 0 ? (
          <p className={styles.noTasksMessage}>No completed tasks found.</p>
        ) : (
          <>
            <table className={styles.taskTable}>
              <thead>
                <tr>
                  <th>#</th>
                  <th onClick={() => handleSort('taskName')} className={styles.sortableColumn}>
                    Task Name {sortConfig.key === 'taskName' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </th>
                  <th onClick={() => handleSort('completedAt')} className={styles.sortableColumn}>
                    Completed At {sortConfig.key === 'completedAt' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </th>
                  <th onClick={() => handleSort('timeSinceCompletion')} className={styles.sortableColumn}>
                    Time Since Completion {sortConfig.key === 'timeSinceCompletion' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {displayedTasks.map((task, index) => (
                  <tr key={task._id}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{task.formData.taskName || 'Unnamed Task'}</td>
                    <td>{new Date(task.completedAt).toLocaleString()}</td>
                    <td>{formatDistanceToNow(new Date(task.completedAt))}</td>
                    <td>
                      <button
                        className={styles.viewResultsButton}
                        onClick={() => {
                          setExpandedTask(task._id === expandedTask ? null : task._id);
                          fetchTaskResults(task._id);
                        }}
                      >
                        {task._id === expandedTask ? 'Collapse' : 'View Results'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {totalPages > 1 && (
              <div className={styles.pagination}>
                <button
                  className={styles.pageButton}
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className={styles.pageButton}
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}

            {/* Expanded Task Details */}
            {expandedTask && (
              <div className={styles.taskDetails}>
                <h3>Task Details</h3>
                {loadingResults ? (
                  <p>Loading results...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : selectedTaskResults.length > 0 ? (
                  <table className={styles.resultsTable}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Final URL</th>
                        <th>Status</th>
                        <th>Captured URLs</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedTaskResults.map((result, index) => (
                        <tr key={result._id}>
                          <td>{index + 1}</td>
                          <td>
                            <a
                              href={result.finalUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              title={result.finalUrl}
                            >
                              {result.finalUrl.length > 50
                                ? `${result.finalUrl.substring(0, 47)}...`
                                : result.finalUrl}
                            </a>
                          </td>
                          <td>{result.status}</td>
                          <td>
                            <details>
                              <summary>View Captured URLs</summary>
                              <ul>
                                {result.capturedUrls?.map((url, i) => (
                                  <li key={i}>
                                    <a
                                      href={url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title={url}
                                    >
                                      {url.length > 50 ? `${url.substring(0, 47)}...` : url}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </details>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No results available for this task.</p>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default TaskResult;
