// src/components/create/CreatePP.js
import React, { useState } from 'react';
import axios from 'axios';
import styles from './CreatePP.module.css';

const ppFields = [
  'XAuthKey', 
  'XAuthClientId', 
  'OfferId', 
  'domainName', 
  'APIName'
];

function CreatePP({ onCreateNetwork }) {
  const [formData, setFormData] = useState({
    name: '',
    template: 'ProfitPixels', // Fixed template for PP
    XAuthKey: '',
    XAuthClientId: '',
    OfferId: '',
    domainName: '',
    APIName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Endpoint for creating ProfitPixels networks
    const createEndpoint = `${process.env.REACT_APP_API_URL}/api/pp/networks/create`;

    try {
      // Create the ProfitPixels network
      const createResponse = await axios.post(createEndpoint, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const newPP = createResponse.data;

      // Notify parent component of successful creation
      if (onCreateNetwork) onCreateNetwork(newPP);

      // Trigger download using the network's ID
      const downloadEndpoint = `${process.env.REACT_APP_API_URL}/api/pp/networks/${newPP._id}/download`;
      const downloadResponse = await axios.get(downloadEndpoint, { responseType: 'blob' });

      const blob = new Blob([downloadResponse.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${newPP.APIName || 'pp_template'}.zip`;
      link.click();
    } catch (error) {
      console.error('Error creating or downloading ProfitPixels configuration', error);
    }
  };

  return (
    <div className={styles.card2}>
      <h2>Please Enter Your API Credentials</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label>Network Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Render form fields specific to ProfitPixels template */}
        {ppFields.map((field) => (
          <div className={styles.formGroup} key={field}>
            <label>{field}:</label>
            <input
              type="text"
              name={field}
              value={formData[field] || ''}
              onChange={handleChange}
              required
            />
          </div>
        ))}

        <div className={styles.formActions}>
          <button type="submit" className={styles.createButton}>
            Create
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreatePP;
